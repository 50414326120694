<template>
    <div class="lamps">
        <b-container>
            <div class="header">
                <b-row>
                    <b-col class="logo" lg="5">
                        <h1 style="margin-top:21px;display:none;">Žarnice za projektor</h1>
                        <router-link to="/"><img class="logo-img" src="/img/logo_grey_text.png" alt="Žarnice za projektor"></router-link>
                    </b-col>
                    <b-col lg="7" class="subtitle">
                        <h2>Orginalne, kvalitetne in generične.<br>Dostava naslednji dan na vaš naslov.</h2>
                    </b-col>
                </b-row>
            </div>
            <div class="content">
                <h3 v-if="lamps.length > 0">Izberite med spodaj navedenimi produkti:</h3>
                <h3 v-else>Za izbrani model projektorja ni na voljo nobene žarnice.</h3>
                <b-row style="margin-top:40px;padding-bottom:50px;">
                    <b-col class="lamp" :offset-lg="coloffset(index+1)" lg="4" v-for="(lamp,index) in lamps" v-bind:key="index">
                        <div class="lamp-content" @click="lampSelect(lamp)">
                            <div class="lamp-logo">
                                <img :src="'/img/' + lampLogo(lamp)" :alt="lampLogo(lamp)">
                            </div>
                            <div class="lamp-text">
                                <h4 class="type">{{ lamp.type_sl }}</h4>
                                <p class="for">za</p>
                                <p class="model">{{ lamp.manufacturer }} {{ lamp.model }}</p>
                                <p class="price">{{ numToCurrency(lamp.priceWT) }}</p>
                                <p class="priceNT">({{ numToCurrency(lamp.price) }} + DDV)</p>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <h3>Ali pa poiščite žarnice za kakšen <router-link to="/" style="color:#4F87A5;">drug model projektorja</router-link>.</h3>
            </div>
            <b-modal v-if="modalShow" v-model="modalShow" size="xl" ok-only>
                <b-container fluid>
                    <b-row>
                        <b-col lg="6">
                            <img :src="'/img/' + lampLogo(selectedLamp)" :alt="lampLogo(selectedLamp)" style="max-height:50px;margin-bottom:20px;">
                            <p class="mtype">{{ selectedLamp.type_sl }}</p>
                            <p class="mmodel">za {{ selectedLamp.manufacturer }} {{ selectedLamp.model }}</p>
                            <p class="mdesc">{{ selectedLamp.description_sl }}</p>
                        </b-col>
                        <b-col lg="6">
                            <p style="font-size:18px;font-weight:bold;margin-bottom:12px;">Podatki o žarnici:</p>
                            <div class="mdetails">
                                <p v-if="selectedLamp.lamp_wattage"><strong>Moč: </strong>{{ selectedLamp.lamp_wattage }} W</p>
                                <p v-if="selectedLamp.lamp_hours"><strong>Življenjska doba: </strong>{{ selectedLamp.lamp_hours }} ur</p>
                                <p v-if="selectedLamp.lamp_type"><strong>Vrsta: </strong>{{ selectedLamp.lamp_type }}</p>
                                <p v-if="selectedLamp.mpn"><strong>Šifra: </strong>{{ selectedLamp.mpn }}</p>
                                <p v-if="selectedLamp.warranty"><strong>Garancija: </strong>{{ selectedLamp.warranty }} {{ sklMesec(selectedLamp.warranty) }}</p>
                            </div>
                            <hr>
                            <div class="msp">
                                <p v-if="selectedLamp.stock > 0">{{ selectedLamp.stock }} {{ sklKos(selectedLamp.stock) }} za zalogi</p>
                                <div v-else>
                                    <p style="color:red;margin-bottom:0px;">Ni na zalogi</p>
                                    <p style="font-size:14px;">Za več informacij prosim pokličite</p>
                                </div>
                                <p class="mprice">{{ numToCurrency(selectedLamp.priceWT) }}</p>
                                <p class="mpriceNT">({{ numToCurrency(selectedLamp.price) }} brez DDV)</p>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
                <template v-slot:modal-footer="{ hide }">
                    <b-button size="md" style="background-color:#4F646F" @click="toAbout()">
                        {{ selectedLamp.stock > 0 ? "Naročilo" : "Kontakt" }}
                    </b-button>
                </template>
            </b-modal>
            <div class="footer">
                <p>&copy; {{ year }} TRGOFON d.o.o. | <i><router-link to="/o-nas" style="color:#4F87A5;">O nas</router-link></i></p>
            </div>
        </b-container>
    </div>
</template>
<script>
export default {
    name: 'Lamps',
    title: 'Žarnice za projektor',
    metaInfo() {
        return {
            meta: [
                { name: 'description', content: 'Originalne, kvalitetne in generične žarnice za projektor ' + this.$route.params.manufacturer + this.$route.params.model },
                { property: 'og:title', content: "Žarnice za projektor | Dostava NASLEDNJI dan, 45.000 žarnic"},
                { property: 'og:site_name', content: 'Žarnice za projektor'},
                { property: 'og:type', content: 'website' },
                { name: 'robots', content: 'index,follow' } 
            ],
            link: [
                { rel: 'canonical', href: 'https://www.zarnice-za-projektor.si/#/' + this.$route.params.manufacturer + '/' + this.$route.params.model }
            ]
        };
    },
    data() {
        return {
            lamps: [],
            selectedLamp: null,
            modalShow: false
        }
    },
    mounted() {
        this.getLampsFromApi();
    },
    computed: {
        manu_slug: function() {
            return this.$route.params.manufacturer
        },
        model_slug: function() {
            return this.$route.params.model
        },
        year: function() {
            var d = new Date();
            return d.getFullYear();
        }
    },
    methods: {
        getLampsFromApi() {
          this.$axios
            .get(process.env.VUE_APP_API_URL + 'getters.php?req=lamps&manufacturer=' + this.manu_slug + '&model=' + this.model_slug)
            .then(response => (this.lamps = response.data))
            .catch(error => console.log(error))
            .finally(this.setPageTitle())
        },
        setPageTitle() {
            if (this.lamps[0]) {
                console.log(1)
                this.$title = 'Žarnica za ' + this.lamps[0].manufacturer + ' ' + this.lamps[0].model + ' | Žarnice za projektor';
                this.$metaInfo.title = this.lamps[0].manufacturer + ' ' + this.lamps[0].model + ' | Žarnice za projektor';
                this.$metaInfo.meta[0].content = 'Originalne, kvalitetne in generične žarnice za projektor ' + this.lamps[0].manufacturer + ' ' + this.lamps[0].model;
            } else {
                this.$title = 'Žarnica za ' + this.manu_slug + ' ' + this.model_slug + ' | Žarnice za projektor';
                this.$metaInfo.title = this.manu_slug + ' ' + this.model_slug + ' | Žarnice za projektor';
                this.$metaInfo.meta[0].content = 'Originalne, kvalitetne in generične žarnice za projektor ' + this.manu_slug + ' ' + this.model_slug;
            }
        },
        lampLogo(lamp) {
            if (lamp.type_logo) {
                return lamp.type_logo
            } else {
                return lamp.seller_logo
            }
        },
        numToCurrency(num) {
            num = parseFloat(num)
            return (
                num
                    .toFixed(2)
                    .replace('.', ',')
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
            )
        },
        lampSelect(lamp) {
            this.selectedLamp = lamp;
            this.modalShow = true;
        },
        coloffset(index) {
            var l = this.lamps.length
            var d = l % 3;
            if (d === 2) {
                if (l-index === 1) {
                    return 2
                } else {
                    return 0
                }
            } else if (l === 1) {
                return 4
            } else if (l === 4) {
                if (index % 2 === 1) {
                    return 2
                } else {
                    return 0
                }
            }
        },
        sklKos(kosov) {
            switch (kosov) {
                case '1':
                    return 'kos'
                    break;
                case '2':
                    return 'kosa'
                    break;
                case '3':
                    return 'kosi'
                    break;
                case '4':
                    return 'kosi'
                    break;
                default:
                    return 'kosov'
                    break;
            }
        },
        sklMesec(mesecev) {
            switch (mesecev) {
                case '1':
                    return 'mesec'
                    break;
                case '2':
                    return 'meseca'
                    break;
                case '3':
                    return 'meseci'
                    break;
                case '4':
                    return 'meseci'
                    break;
                default:
                    return 'mesecev'
                    break;
            }
        },
        toAbout() {
            this.$router.push({path:'/o-nas'});
        }
    },
    watch: {
        lamps: function(val) {
            this.setPageTitle()
        }
    }
}
</script>

<style scoped>

.lamps {
    color: #4F646F;
    /* background-color: #DEE7E7; */
    /* box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 8px 22px 0 rgba(0, 0, 0, 0.19); */
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px 30px;
    min-height: 100vh;
}

.header {
    text-align: center;
    padding: 40px 30px;
    color: #535657;
}
h1 {
    font-size: 40px;
    font-weight: 700;
}
.logo-img {
    max-height:95px;
    max-width: 100%;
}
h2 {
    font-size: 25px;
    margin-top:20px;
    font-weight: 700;
}
.subtitle {
    border-left: 2px solid #666666;
}
h3 {
    font-size: 23px;
    margin-top: 20px;
    text-align: center
}

.lamp-content {
    /* border: 1px solid #439A86; */
    background-color: #DEE7E7;
    color: #4F646F;
    margin: 10px 0px;
    /* border-radius: 5px; */
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 8px 22px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    transition: transform .2s;
}
.lamp-content:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.29);
    cursor: pointer;
    transform: scale(1.02);
}
.lamp-logo {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    /* border-top-left-radius: 5px; */
    /* border-top-right-radius: 5px; */
    background-color: #F4FAFF;
}
.lamp-logo>img {
    max-height:40px;
}
.lamp-text {
    padding: 15px;
}
.type {
    font-size: 26px;
    margin-top:7px;
    margin-bottom: 7px;
}
.for {
    font-size:15px;
    margin-bottom: 0px;
}
.model {
    font-size: 17px;
}
.price {
    margin-top:25px;
    margin-bottom: 0px;
    font-size: 28px;
}
.priceNT {
    margin-top:0px;
    font-size:15px;
}
.desc {
    font-size:15px;
}
.footer {
    margin-top: 70px;
    background-color: #F4FAFF;
    padding: 10px 0px;
    text-align: center;
}
.mtype {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 2px;
}
.mmodel {
    font-size: 15px;
}
.mdesc {
    font-size: 15px;
}
.mdetails>p {
    margin-bottom:2px;
    font-size: 15px;
}
.msp {
    margin-top: 50px;
    text-align: right;
    font-size: 20px;
}
.mprice {
    margin-top: 25px;
    font-size:26px;
    font-weight: bold;
    margin-bottom: 0px;
}
.mpriceNT {
    font-size: 15px;
}
@media (max-width:992px){
    .subtitle {
        border-left:none !important;
        border-top: 2px solid #666666;
    }
    .msp {
        margin-top: 0px;
        text-align: left;
    }
    .logo-img {
        margin-bottom: 20px;
    }
}

</style>