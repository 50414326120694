<template>
    <div class="about">
        <b-container>
            <div class="header">
                <b-row>
                    <b-col class="logo" lg="5">
                        <h1 style="margin-top:21px;display:none;">Žarnice za projektor</h1>
                        <router-link to="/"><img class="logo-img" src="/img/logo_grey_text.png" alt="Žarnice za projektor"></router-link>
                    </b-col>
                    <b-col lg="7" class="subtitle">
                        <h2>Orginalne, kvalitetne in generične.<br>Dostava naslednji dan na vaš naslov.</h2>
                    </b-col>
                </b-row>
            </div>
            <div class="content">
                <h2 style="text-align:center;font-size:30px;">Kontaktirajte nas</h2>
                <h3>Naročilo oddajte na e-mail, dodatne informacije pa dobite na GSM</h3>
                <p style="font-size:26px;margin-top:40px;text-align:center;"><strong><i>TRGOFON d.o.o.</i></strong></p>
                <b-row class="info-row">
                    <b-col lg="4">
                        <div class="details">
                            <a href="mailto:info@trgofon.com"><img style="cursor:pointer;" src="img/email_icon.png" alt="Epošta"></a>
                            <p style="margin-top:15px;"><a href="mailto:info@trgofon.com">info@trgofon.com</a></p>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="details">
                            <a href="tel:+38651224113"><img style="cursor:pointer;" src="img/phone_icon.png" alt="Telefon"></a>
                            <p style="margin-top:15px;"><a href="tel:+38651224113">+386 (51) 224 113</a></p>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="details">
                            <img src="img/map_icon.png" alt="Naslov">
                            <p>Uršičev štradon 26<br>1000 Ljubljana</p>
                        </div>
                    </b-col>
                </b-row>
                <hr>
                <h3 style="margin-top:30px;">Dobavljamo originalne, hibridne in kvalitetne žarnice in sijalke</h3>
                <b-row style="margin-top:30px;">
                    <b-col v-for="(brand,index) in brands" v-bind:key="brand.img" class="llogo-col" lg="2" :offset-lg="index == 0 ? 1 : 0">
                        <img :id="'popover-' + index" class="llogo" :src="'img/' + brand.img" :alt="brand.title" :style="brand.style">
                        <b-popover :target="'popover-' + index" triggers="hover" placement="top">
                            <template v-slot:title>{{ brand.title }}</template>
                            <strong>{{ brand.warranty }} garancije</strong><br>
                            {{ brand.description }}
                        </b-popover>
                    </b-col>
                </b-row>
                <hr>
                <h3 style="margin-top:30px;">Druge povezave</h3>
                <b-row style="margin-top:25px;padding:10px;margin-bottom:30px;">
                    <b-col lg="3">
                        <div class="links">
                            <a href="https://www.trgofon.com/">www.trgofon.com</a>
                        </div>
                    </b-col>
                    <b-col lg="3">
                        <div class="links">
                            <a href="https://www.pctocka.si/">www.pctocka.si</a>
                        </div>
                    </b-col>
                    <b-col lg="2">
                        <div class="links">
                            <a href="http://www.top3.si/">www.top3.si</a>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="links">
                            <a href="https://www.racunovodska-tocka.si/">www.racunovodska-tocka.si</a>
                        </div>
                    </b-col>
                </b-row>
                <!-- <hr> -->
                <div role="tablist">
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1 acc-tab" role="tab">
                            <h3 v-b-toggle.info-1>Splošni pogoji</h3>
                        </b-card-header>
                        <b-collapse id="info-1" accordion="info" role="tabpanel">
                            <b-card-body>
                                <div class="terms">
                                    <p><strong>1. Spletna trgovina zarnice-za-projektor.si</strong><br>
                                    <strong>zarnice-za-projektor</strong>.si je blagovna znamka podjetja TRGOFON d.o.o.<br>
                                    Uporabnikom omogoča spletni nakup vseh žarnic za projektorje na enem mestu z izjemno hitro dobavo (običajno žarnice za projektorje dobavimo v 2 dneh ali že naslednji dan po plačilu oz. izdani naročilnici glej točko 10.4. Preprost filter žarnic za projektorje omogoča hiter pregled zaloge žarnic vsaj dveh ponudnikov žarnic in prikaže samo žarnice, ki so na zalogi in imajo poleg tega še najugodnejšo ceno. Ker se zavedamo, da je ponovno delovanje projektorja za vas zelo pomembno, tako najhitreje za hitro dobavo žarnice za projektor poskrbimo mi.</p>
                                    <p><strong>2. Žarnice</strong><br>
                                    Ponujamo več vrst žarnic za projektorje.<br>
                                    Spletna stran <strong>zarnice-za-projektor.si</strong> vam nudi<br>
                                    • originalne žarnice<br>
                                    • originalne žarnice v generičnih ohišjih ali nevtralni embalaži (DiamondLamp ali QualityLamp žarnice)<br>
                                    • originalne sijalke<br>
                                    • ter kvalitetne generične žarnice<br>
                                    DiamondLamp ali QualityLamp žarnice, so enake kakovosti kot originalne žarnice. Za njih veljajo enaki garancijski pogoji in tehnične značilnosti (svetilnost, življenjska doba…) kot jih imajo originalne žarnice.<br>
                                    Na izbiro imate tudi nakup samih originalnih sijalk kjer staro originalno ohišje uporabite z novo sijalko. Pri menjavi sijalke je potrebno tehnično znanje, ustrezno orodje, primerna zaščitna oprema-rokavice, ročne spretnosti ter upoštevati vsa navodila za pravilno in varno ravnanje, saj sijalke vsebujejo izjemno strupene in zdravju nevarne pline. Garancijska doba v primeru neupoštevanja vseh pravil ali neustrezne menjave sijalke ne velja.</p>
                                    <p><strong>3. Obdelava vašega naročila</strong><br>
                                    Ko boste izvedli naročilo-nakup, vam bomo na vaš elektronski naslov poslali predračun za naročene žarnice. Po plačilu predračuna nas o tem obvestite in na naš elektronski naslov info@trgofon.com pošljite potrdilo, ki izkazuje nakazilo. Žarnice bomo pri našem dobavitelju naročili in vas obvestili, ko bomo žarnice prejeli v naše skladišče. Če ste opravili naročilo z dostavo na vaš naslov, vam bomo žarnico z našim ponudnikom hitre pošte poslali v najkrajšem možnem času. Dostava na vaš naslov velja samo za območje republike Slovenije. Če pa ste opravili nakup žarnice za projektor z osebnim prevzemom v našem skladišču pa se bomo dogovorili za točen termin prevzema.</p>
                                    <p><strong>4. Garancija</strong><br>
                                    Zagotavljamo vam<br>
                                    • 3 mesece garancije za originalne žarnice in originalne sijalke<br>
                                    • 4 mesece garancije za QualityLamp žarnice<br>
                                    • 6 mesecev garancije na DiamondLamp in Smart Lamp žarnice<br>
                                    • 4 mesece garancije na generične žarnice<br>
                                    Garancijski rok prične teči od datuma izdaje blaga iz našega skladišča.</p>
                                    <p><strong>5. Reklamacije</strong><br>
                                    V primeru reklamacije, smo za vas dosegljivi na telefonski številki 051 224 113 ali nam pošljite elektronsko sporočilo<strong> info@trgofon.com</strong>. Težavo bomo poizkušali rešiti v najkrajšem možnem času.</p>
                                    <p><strong>6. Povratna informacija</strong><br>
                                    Naš cilj in želja je, da bi bila naša spletna stran zarnice-za-projektor.si in storitve, ki jih ponujamo, popolne. Zato vas prosimo, da nas o svojih predlogih, pohvalah ali grajah obvestite na elektronski naslov info@trgofon.com</p>
                                    <p><strong>7. Pravno obvestilo in pogoji uporabe</strong><br>
                                    Pravno obvestilo se nanaša na vse strani in podstrani v sklopu domen:<br>
                                    • zarnice-za-projektor.si<br>
                                    • trgofon.com<br>
                                    • pctocka.si<br>
                                    Na podlagi Zakona o avtorski in sorodnih pravicah (ZASP) je podjetje TRGOFON d.o.o. (v nadaljevanju TRGOFON oz. podjetje) nosilec materialnih avtorskih pravic nad vsebinami v najširšem pomenu (besedila, fotografije, skice, zemljevidi in načrti, avdio-video posnetki) objavljenimi na omenjenih spletnih straneh. To pomeni, da je brez pisnega dovoljenja podjetja prepovedano vsakršno kopiranje, prepisovanje, razmnoževanje in kakršno koli razširjanje v komercialne namene.<br>
                                    Z zakonom (ZASP, členi 48, 49, 51) sta izjemoma dovoljena uporaba oz. reproduciranje avtorskega dela na podlagi pravice javnosti do obveščenosti, v izobraževalne namene ter za ponazoritev, soočenje in napotitev v citatni obliki, vendar je pri tem obvezno navesti vir in avtorstvo dela. ZASP (člen 50) dovoljuje tudi uporabo in reproduciranje avtorskega dela v omejenem številu za zasebno, nekomercialno uporabo.</p>
                                    <p><strong>7.1 Varstvo osebnih podatkov</strong><br>
                                    Podjetje zagotavlja, da v skladu z Zakonom o varstvu osebnih podatkov (ZVOP) po svojih najboljših močeh varuje osebne podatke uporabnikov na tej spletni strani.<br>
                                    To pomeni, da podjetje zbira osebne podatke, samo kadar jih uporabnik posreduje. Podjetje se obvezuje, da bo zbrane podatke hranilo in uporabljalo samo toliko časa, kot bo potrebno za dosego namena, zaradi katerega so bili zbrani.<br>
                                    Podjetje osebnih podatkov uporabnikov ne bo posredovalo nobeni drugi, fizični ali pravni, osebi. V primeru sodelovanja z drugimi podjetji, ki za podjetje opravljajo storitve (npr. priprava in izvedba nagradne igre ali izdelava spletne strani), bo tem omogočen dostop do podatkov, ki so nujno potrebni za izvedbo njihove storitve. Vsa ta podjetja so obvezana, da dostopa do podatkov ne bodo zlorabila in jih bodo uporabljala izključno za izvedbo storitve.</p>
                                    <p><strong>7.2 Ne/spoštovanje določb iz Pravnega obvestila</strong><br>
                                    Podjetje in avtorji vsebin, objavljenih na spletnih straneh, od uporabnika pričakujejo, da bo spoštoval zapisane določbe in zakone s področja varovanja zasebnosti, prava intelektualne lastnine ter avtorskega prava.</p>
                                    <p><strong>7.3 Odgovornost Podjetja</strong><br>
                                    Podjetje se obvezuje, da bo v dobri veri in v okviru svojih zmožnosti skrbelo za pravilnost in ažurnost objavljenih vsebin na spletnih straneh.<br>
                                    S tem podjetje ne izključuje možnosti za pojav napak med objavljenimi vsebinami. Vse informacije posredovane prek tega spletnega mesta so tako zgolj informativne narave, saj podjetje ne more prevzeti odgovornosti za njihovo absolutno točnost. Vse cene so zgolj okvirne in fotografije izdelkov simbolične.<br>
                                    Narava svetovnega spleta in spletnega mesta onemogoča nadzor nad vsebinami, do katerih vodijo povezave s tega spletnega mesta. Zaradi tega podjetje ne prevzema nikakršne odgovornosti za vsebine, ki so uporabniku dostopne preko teh povezav.<br>
                                    Vse strani in storitve v sklopu spletnih strani podjetja, uporabljate na lastno odgovornost. Podjetje tudi ne more in ne odgovarja za kakršnekoli poškodbe računalniške ali druge opreme in aplikacij, ki se koristijo za dostop do spletnih strani podjetja.</p>
                                    <p><strong>8. Sprememba cen</strong><br>
                                    Vse cene so lahko predmet spremembe. Podjetje si pridržuje pravico do spremembe cen glede na spremembe cen proizvajalcev, spremenjene tržne pogoje, ukinitve produktov, napak v oglaševanju, spremembe valutnih razmerij in ostalih dejavnikov. V primeru spremembe cen po že oddanem naročilu z vaše strani vas bomo pred izvršitvijo naročila obvestili. Ob potrditvi naročila s spremenjeno cene bomo naročilo dokončno izvršili.</p>
                                    <p><strong>9. Napake pri obveščanju</strong><br>
                                    Vedno se trudimo posredovati točne in korektne informacije. Ker pa so naše informacije točne le v tolikšni meri, kot nam jih posreduje dobavitelj zaradi tega ne moremo prevzeti odgovornosti za napake, ki so posledica napačnega obveščanja.</p>
                                    <p><strong>10. Pravila in pogoji prodaje</strong><br>
                                    TRGOFON d.o.o., kot lastnik spletne strani zarnice-za-projektor.si, sprejema naročila le pod pogoji navedenimi spodaj. Ti pogoji se ne morejo spremeniti, razen če ni drugače dogovorjeno z direktorjem podjetja TRGOFON d.o.o. v pisni obliki.</p>
                                    <p><strong>10.1 Naročila</strong><br>
                                    Na podlagi vašega naročila, boste avtomatsko prejeli predračun na elektronski naslov, ki ste ga uporabili ob naročilu, vendar to še ne pomeni da smo naročilo zavezani sprejeti. Naročilo lahko zavrnemo brez kakršnekoli obrazložitve. Žarnice bomo naročili pri dobavitelju po nakazilu sredstev na tekoči račun izpisan na predračunu ali izdane naročilnice za prejeti predračun. Glede na izbrano dostavo ob naročilu (osebni prevzem ali dostavo na vaš naslov) vas bomo temu primerno obvestili ali vam žarnice kar dostavili na naslov oddan ob naročilu Vaše naročilo lahko odpoveste kadarkoli v času od trenutka, ko ste nam poslali naročilo do trenutka, ko poravnate znesek na predračunu oziroma izdate naročilnico po prejetem predračunu. Naročila po plačilu zneska na poslanem predračunu oziroma izdani naročilnici, ne morete več odpovedati, razen pod pogoji opisanimi v rubriki »Vračila«</p>
                                    <p><strong>10.2 Stroški</strong><br>
                                    Stroški dostave blaga po Sloveniji&nbsp;so brezplačni.</p>
                                    <p><strong>10.3 Blago</strong><br>
                                    Vse žarnice so predmet razpoložljivosti. Naročene žarnice, ki zaradi kakršnih koli razlogov ni več na zalogi, jo lahko zamenjamo z drugo žarnico z enakimi lastnostmi brez predhodnega obvestila.</p>
                                    <p><strong>10.4 Dostava blaga</strong><br>
                                    Običajni rok dobave žarnic, ki jih ima dobavitelj na zalogi je 1 do 3 delovne dni po nakazilu zneska na predračunu oziroma izdani naročilnici. V kolikor je nakazilo izvedeno do 12.00 ure in boste žarnico prevzeli v našem skladišču sami, boste to lahko običajno storili že naslednji dan po nakazilu (proti koncu delovnega dne). Odgovornosti za dostavo v tem roku ne moremo prevzeti v primeru neuspešne dobave dobavitelja ali višje sile pri transportu. Rok dobave žarnic, ki jih dobavitelj nima na zalogi vam podamo na podlagi predvidevanja posameznega dobavitelja. Za ta rok ne moremo prevzeti odgovornosti, saj smo odvisni od določenega dobavitelja.</p>
                                    <p><strong>10.5 Poškodovanje blaga</strong><br>
                                    Tveganje poškodovanja blaga med transportom iz našega skladišča do kupca prevzame kupec blaga. Odgovornost za dostavo blaga od trenutka, ko oddamo blago iz našega skladišča prevzame kupec.</p>
                                    <p><strong>10.6 Kakovost blaga</strong><br>
                                    Zagotavljamo, da bo blago, ki ga tržimo na spletni strani <strong>zarnice-za-projektor.si</strong> ustrezne kakovosti (ob upoštevanju vseh pogojev na tej spletni strani). Ker nismo proizvajalec blaga vam bomo za žarnice priznali garancije iz točke 4.</p>
                                    <p><strong>10.7 Zavrnitev blaga</strong><br>
                                    Če boste prejeli blago vidno poškodovano takoj obvestite prevoznika, da blaga ne boste sprejeli ter napišite zapisnik o poškodovanem blagu, ki naj ga podpiše prevoznik. V nasprotnem primeru ne boste mogli uveljavljati reklamacije pri prevozniku.</p>
                                    <p><strong>10.8 Višja sila</strong><br>
                                    Odgovornosti ne prevzemamo za napake oziroma dostave, ki so posledica višje sile. Če dobava zamuja zaradi višje sile, vas bomo o tem obvestili takoj, ko bo to mogoče in vam posredovali dodatne informacije o roku dobave.</p>
                                    <p><strong>10.9 Vračilo blaga</strong><br>
                                    Če ste po pomoti naročili napačno žarnico, bomo žarnico pod določenimi pogoji in plačilu stroškov sprejeli nazaj. Žarnico nam lahko vrnete, če je popolnoma nepoškodovana, tako žarnica kot embalaža (nepoškodovana varnostna nalepka, embalaža mora biti originalna, brez kakršnihkoli oznak, napisov, z originalnimi nalepkami, originalno vsebnostjo znotraj embalaže, škatla še ni bila odprta…). Ob vračilu vam bomo zaračunali stroške vračila, ki običajno znašajo 35€ +DDV, v nobenem primeru pa ne več kot 30% vrednosti žarnice. Vračilo ni možno edino v primeru, da vas že ob naročilu informiramo, da gre za posebno žarnico pri kateri vračilo ni možno.<br>
                                    Če žarnica ob vgradnji v projektor ne deluje zaradi okvare na sami žarnici, potem nam žarnico vrnite in poslali vam bomo novo. Preden vam bomo poslali novo žarnico bodo žarnico pregledali naši strokovnjaki oz. strokovnjaki proizvajalca in ugotovili ali gre za garancijsko napako. Če želite novo žarnico prejeti pred pregledom in ugotovitvami strokovnjakov glede reklamirane žarnice, potem prosimo naročite novo žarnico ponovno. Za pokvarjeno žarnico pod garancijo, vam bomo povrnili celotno kupnino. V primeru, da smo vam dobavili napačno žarnico, vam jo bomo vzeli nazaj brez kakršnih koli stroškov in vam dobavili ustrezno žarnico.</p>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1 acc-tab" role="tab">
                            <h3 v-b-toggle.info-2>Uporabni nasveti</h3>
                        </b-card-header>
                        <b-collapse id="info-2" accordion="info" role="tabpanel">
                            <b-card-body>
                                <div class="terms">
                                    <p><strong>Podaljšajte življenjsko dobo žarnici</strong><br>
                                    Nikoli se žarnice ali sijalke ne dotikajte s prsti. Vedno uporabite rokavice ali nežno tkanino. Odtisi od prstov povzročajo temperaturne »vroče točke«. To v daljšem obdobju pripelje do znatnega poškodovanja žarnice in odpoved. Redno čistite filter projektorja. Okvarjeni ventilatorji povzročijo pregrevanje žarnice in prezgodnjo okvaro. Pooblaščeni servisi lahko takšno napako odkrijejo, kar pomeni, da garancija za žarnico ne velja več. Zaradi tega priporočamo redno čiščenje filtra. Zagotovite tudi, da je okoli projektorja zagotovljen dovolj velik pretok zraka.<br>
                                    V kolikor vgrajujete žarnico v obstoječe modularno ohišje, poskrbite, da priključni terminali ne bodo premočno pritrjeni saj bi lahko počila keramična izolacija in s tem zmanjšala izolativnost. Nasprotno, če so priključni terminali premalo pričvrščeni lahko pride do iskrenja kar pa lahko poškoduje napajalnik. Pri vtičnih žarnicah poskrbite, da bodo žarnice popolnoma legle v svoje ležišče. Praviloma se ustavite pri prvih znakih upora.</p>
                                    <p><strong>Ugašanje projektorja</strong><br>
                                    Vedno ugašajte projektor tako, da ga ugasnete preko daljinskega upravljalnika ali tipk za upravljanje. Projektorja nikoli ne izključite tako, da izvlečete napajalni kabel iz projektorja ali vtičnice. Po izklopu slike, mora namreč projektor s svojim ventilatorjem še ohladiti žarnico, za kar potrebuje električno energijo. Šele, ko se ventilator v projektorju ugasne lahko izklopite električno napajanje.</p>
                                    <p><strong>Udarci ali vibracije</strong><br>
                                    Žarnica se lahko pokvari tudi zaradi mehanskih premikov, udarcev. Vroča žarnica je zelo občutljiva na tresljaje, zato se prepričajte, da se je žarnica ohladila preden premikate projektor.</p>
                                    <p><strong>Ekonomičen način delovanja projektorja</strong><br>
                                    Če vam projektor omogoča izbiro med »visoko/nizko« (v angleškem meniju »high/low ali »economy/normal » mode) opcijo delovanja žarnice izberite »nizko« delovanje (low oz. economy v angleškem meniju) saj bo žarnica tako manj obremenjena, kar bo povečalo njeno življenjsko dobo.</p>
                                    <p><strong>Vklop in izklop projektorja</strong><br>
                                    PRED VKLOPOM PROJEKTORJA NAJ SE PROJEKTOR IN ŽARNICA SEGREJETA NA SOBNO TEMPERATURO!<br>
                                    To je še posebno pomembno v zimskem času. Za žarnico je najbolje, da se čim manjkrat vklaplja, torej premislite, kdaj boste projektor resnično potrebovali. Če boste projektor po končani uporabi zopet uporabljali v roku 30 do 60 minut je bolj smotrno, da ostane prižgan, kot pa da ga ugasnete.</p>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </div>
            <div class="footer">
                &copy; {{ year }} TRGOFON d.o.o.
            </div>
        </b-container>
    </div>
</template>
<script>
export default {
    name: 'Kontakt',
    title: 'O nas | Trgofon d.o.o. | Žarnice za projektor',
    metaInfo() {
        return { 
            meta: [
                { name: 'description', content: 'Originalne, kvalitetne in generične žarnice za projektor znamke Optoma, Nec, InFocus, Benq, Acer, Panasonic, ... dostava NASLEDNJI dan. Več: 051 224 113.'},
                { property: 'og:title', content: "Žarnice za projektor | Dostava NASLEDNJI dan, 45.000 žarnic"},
                { property: 'og:site_name', content: 'Žarnice za projektor'},
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ],
            link: [
                { rel: 'canonical', href: 'https://www.zarnice-za-projektor.si/#/o-nas' }
            ]
        }
    },
    data() {
        return {
            brands: [
                {
                    img: 'dl-logo.png',
                    title: 'Diamond Lamps',
                    style: 'margin-top:2px;',
                    warranty: '6 mesecev',
                    description: 'Diamond Lamp žarnica je kombinacija originalne sijalke vgrajene v drugo ohišje. Žarnica ponuja identične rezultate glede svetilnosti, kot jih nudi originalna žarnica, vendar je cenejša in ima mnogo daljšo garancijsko dobo.'
                },{
                    img: 'ql-logo.png',
                    title: 'Quality Lamps',
                    style: 'margin-top:8px;',
                    warranty: '4 meseci',
                    description: 'Quality Lamp žarnica je kombinacija originalne sijalke vgrajene v drugo ohišje. Žarnica ponuja identične rezultate glede svetilnosti, kot jih nudi originalna žarnica, vendar je cenejša in ima daljšo garancijsko dobo.'
                },{
                    img: 'oem_lamps.png',
                    title: 'OEM Lamps',
                    style: null,
                    warranty: '3 meseci',
                    description: 'Žarnica, ki je tovarniško vgrajena v projektor. Nudi najboljše rezultate glede življenjske dobe in svetilnosti, vendar je ponavadi najdražja in ima najkrajšo dobo garancije.'
                },{
                    img: 'oem_bulbs.png',
                    title: 'OEM Bulbs',
                    style: null,
                    warranty: '3 meseci',
                    description: 'Sijalka, ki je vgrajeno v žarnico – brez ohišja. Nudi identične rezultate glede življenjske dobe in svetilnosti kot originalna žarnica, vendar jo je potrebno ročno zamenjati, pri tem pa uporabljati pravilno orodje in zaščitno opremo, saj ob nepravilni uporabi ali zamenjavi lahko povzroči poškodbe projektorja in izgubo garancijskih pravic.'
                },{
                    img: 'sl-logo.png',
                    title: 'Smart Lamps',
                    style: 'margin-top:8px;',
                    warranty: '6 mesecev',
                    description: 'Smart Lamp generične žarnice.  Pri proizvodnji uporabljajo dokazano zelo kvalitetne dele – veliko od teh, jih pri proizvodnji uporabljajo tudi proizvajalci originalnih žarnic. Vse žarnice so sestavljene in testirane po zelo visokih standardih. Žarnice ponuja odlične rezultate glede svetilnosti, so cenovno zelo ugodne in nudijo dolgo garancijsko dobo.'
                }
            ]
        }
    },
    computed: {
        year: function() {
            var d = new Date();
            return d.getFullYear();
        }
    }
}
</script>

<style scoped>

.about {
    color: #4F646F;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px 30px;
    min-height: 100vh;
}

.header {
    text-align: center;
    padding: 40px 30px;
    color: #535657;
}
h1 {
    font-size: 40px;
    font-weight: 700;
}
.logo-img {
    max-height:95px;
    max-width: 100%
}
h2 {
    font-size: 25px;
    margin-top:20px;
    font-weight: 700;
}
a {
    color: #4F87A5;
}
hr {
    border-top: 1px solid rgba(0,0,0,0.4)
}
.subtitle {
    border-left: 2px solid #666666;
}
h3 {
    font-size: 23px;
    margin-top: 20px;
    text-align: center
}
.footer {
    margin-top: 70px;
    background-color: #F4FAFF;
    padding: 10px 0px;
    text-align: center;
}
.details {
    text-align: center;
}
.details img {
    max-height:45px;
    margin-bottom: 15px;
}
.details>p {
    font-size:20px;
    text-align: center;
}
.info-row {
    margin-top:25px;
    padding:10px 100px;
}
.links {
    font-size:20px;
    text-align: center;
}
.terms {
    font-size:14px;
}
.llogo-col {
    text-align: center;
}
.llogo {
    /* max-height: 65px; */
    max-width: 130px;
    cursor: pointer;
}
.acc-tab {
    cursor: pointer;
    background-color: #DEE7E7;
}
.acc-tab>h3 {
    margin-top:8px;
    margin-bottom: 8px;
}
@media (max-width:992px){
    .subtitle {
        border-left:none !important;
        border-top: 2px solid #666666;
    }
    .llogo {
        max-height: 45px;
        max-width: 200px;
        cursor: pointer;
        margin-bottom: 20px;
        margin-top:0px !important;
    }
    .details>p {
        margin-top:0px !important;
    }
    .logo-img {
        margin-bottom: 20px;
    }
    .info-row {
        padding: 0px;
    }
}

</style>