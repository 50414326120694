<template>
    <div class="section md-padding">
        <b-container>
            <b-row>
                <b-col style="margin-bottom:20px;"><h2>Vsi modeli proizvajalca {{ selectedManName }}</h2></b-col>
            </b-row>
            <b-row>
                <b-col :lg="12">
                    <router-link style="display:block;" v-for="mod in manuModels" :key="mod.slug" :to="{ name: 'Lamps', params: { manufacturer: selectedMan, model: mod.slug }}">{{ mod.name }}</router-link>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
export default {
    name: "home-models",
    props: {
        selectedMan: String,
        manuModels: Array,
        selectedManName: String
    }
}
</script>
<style>
.section {
    position: relative;
}

.md-padding {
    padding-top: 75px;
    padding-bottom: 35px;
}

.section-header {
    text-align: center;
    position: relative;
    margin-bottom: 40px;
}

.section-content {
    text-align: center;
    position: relative;
    margin-bottom: 40px;
}
</style>