import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import App from './App.vue';
import { router } from '@/router';
import Meta from 'vue-meta';
import VuePageTitle from 'vue-page-title';
import axios from 'axios';

Vue.config.productionTip = false;

Vue.prototype.$axios = axios;
Vue.use(VuePageTitle, { router });
Vue.use(BootstrapVue);
Vue.use(Meta);


new Vue({
  el: '#app',
  router,
  template: '<App/>',
  components: {
    App
  }
})