<template>
  <div class="home">
    <div class="header">
      <div class="bg-img">
        <div class="overlay"></div>
      </div>
      <div class="header-wrapper">
        <b-container>
          <b-row>
            <b-col md="10" offset-md="1">
              <div class="header-content">
                <h1 style="color:#fff;display:none;">ŽARNICE ZA PROJEKTOR</h1>
                <img class="logo-img" src="/img/logo_white_text.png" alt="Žarnice za projektor">
                <h2 style="color:#fff;">Orginalne, kvalitetne in generične.<br>Dostava naslednji dan na vaš naslov.</h2>
                <form id="model-search">
                <vue-bootstrap-typeahead 
                    ref="manufacturerac"
                    v-model="manufacturer"
                    :data="manufacturers"
                    size="lg"
                    :serializer="s => s.name"
                    placeholder="Vnesi naziv proizvajalca projektorja"
                    inputClass="manufacturer-ac"
                    style="margin:40px auto 10px;max-width:500px;"
                    @hit="selectedManufacturer = $event.slug"
                    :maxMatches="500"
                    :minMatchingChars="1"
                />
                <vue-bootstrap-typeahead 
                    v-show="modelAC"
                    ref="modelac"
                    v-model="manuModel"
                    :data="manuModels"
                    size="lg"
                    :serializer="s => s.name"
                    placeholder="Vnesi model projektorja"
                    inputClass="model-ac"
                    style="margin:20px auto;max-width:500px;"
                    @hit="selectedModel = $event.slug"
                    :maxMatches="500"
                    :minMatchingChars="1"
                />
                </form>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <home-models v-if="selectedManufacturer" :selectedMan="selectedManufacturer" :manuModels="manuModels" :selectedManName="manuName" />
    <div class="footer">
        <p>&copy; {{ year }} TRGOFON d.o.o. | <i><router-link style="color:#F4FAFF;" to="/o-nas">O nas</router-link></i></p>
        <p>&darr; VSI MODELI</p>
    </div>
  </div>
</template>

<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import HomeModels from './HomeModels.vue';

export default {
  name: 'Home',
  components: {
    HomeModels,
    VueBootstrapTypeahead
  },
  title: "Žarnice za projektor | Dostava NASLEDNJI dan, 45.000 žarnic",
  metaInfo() {
    return { 
        meta: [
            { name: 'description', content: 'Originalne, kvalitetne in generične žarnice za projektor znamke Optoma, Nec, InFocus, Benq, Acer, Panasonic, ... dostava NASLEDNJI dan. Več: 051 224 113.'},
            { property: 'og:title', content: "Žarnice za projektor | Dostava NASLEDNJI dan, 45.000 žarnic"},
            { property: 'og:site_name', content: 'Žarnice za projektor'},
            { property: 'og:type', content: 'website' },    
            { name: 'robots', content: 'index,follow' } 
        ],
        link: [
            { rel: 'canonical', href: 'https://www.zarnice-za-projektor.si/' }
        ]
    }
  },
  data() {
      return {
          manufacturers: [],
          manufacturer: "",
          selectedManufacturer: null,
          manuModels: [],
          manuModel: "",
          selectedModel: null
      }
  },
  mounted() {
    //   console.log(this.$refs.manufacturerac.$el)
    this.getManufacturersFromApi()
    document.getElementsByClassName("manufacturer-ac")[0].focus()
  },
  methods: {
      getManufacturersFromApi() {
          this.$axios
            .get(process.env.VUE_APP_API_URL + 'getters.php?req=manufacturers')
            .then(response => (this.manufacturers = response.data))
            .catch(error => console.log(error))
      },
      getModelsFromApi() {
          this.$axios
            .get(process.env.VUE_APP_API_URL + 'getters.php?req=models&manufacturer=' + this.selectedManufacturer)
            .then(response => (this.manuModels = response.data))
            .catch(error => console.log(error))
            .finally(() => {
                document.getElementsByClassName("model-ac")[0].focus()
            })
      },
      goToModelLamps() {
          this.$router.push('lamps/' + this.selectedManufacturer + '/' + this.selectedModel)
      }
  },
  watch: {
      selectedManufacturer: function(val) {
          this.getModelsFromApi()
      },
      selectedModel: function(val) {
          this.goToModelLamps()
      },
      manufacturer: function(val) {
          if (val == "") {
              this.models = [];
              this.$refs.modelac.inputValue=''
          }
      }
  },
  computed: {
    modelAC: function() {
        if (this.manuModels.length > 0) {
            return true
        } else {
            return false
        }
    },
    year: function() {
        var d = new Date();
        return d.getFullYear();
    },
    manuName: function() {
        if (this.selectedManufacturer != null) {
            for (let i = 0; i < this.manufacturers.length; i++) {
                const e = this.manufacturers[i];
                if (e.slug == this.selectedManufacturer) return e.name;
            }
        } else return null;
    }
  }
}
</script>

<style scoped>

.header {
    height: 100vh;
    position: relative;
    display: block;
}

.bg-img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('/img/projector.jpg');
}

.bg-img .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #1C1D21;
    opacity: .85;
}
.logo-img {
    max-height:18vh;
    max-width: 100%;
    margin-bottom: 20px;
}
.header-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 16%;
    /* transform: translateY(-50%);
    -webkit-transform: translateY(-50%); */
    text-align: center;
}
.header-content {
    color: #fff;
}
.header-content>h1 {
    font-size: 55px;
    font-weight: 700;
    text-transform: uppercase;
}
.header-content>h2 {
    font-size: 30px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 60px;
}

/deep/ .manufacturer-ac {
    text-align:center;
}

/deep/ .model-ac {
    text-align:center;
}

.header-content>button {
    display: inline-block;
    padding: 10px 35px;
    margin: 20px 3px 3px;
    border: 2px solid transparent;
    border-radius: 3px;
    transition: 0.2s opacity;
    -webkit-transition: 0.2 opacity;
}
.footer {
    position: absolute;
    bottom:15px;
    color:#F4FAFF;
    width: 100%;
    text-align: center;
}
@media (max-width:992px){
    .header-content>h2 {
        font-size: 24px;
        margin-bottom: 20px;
    }
    .logo-img {
        margin-bottom: 0px;
    }
    .header-wrapper {
        top: 8%;
    }
    .footer {
        bottom: 0px;
    }
}
</style>
