<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'app'
}
</script>
<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Nunito:400,400i,700,700i&display=swap');
  @import '~bootstrap/scss/bootstrap.scss';
  @import '~bootstrap-vue/src/index.scss';

  body {
    background-color: #F4FAFF;
    margin: 0;
    font-family: 'Nunito', sans-serif;
  }
</style>
