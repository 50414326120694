import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Lamps from '../views/Lamps.vue'
import About from '../views/About.vue'

Vue.use(Router)

export const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: Home,
      meta: {
        title: 'Žarnice za projektor | Dostava NASLEDNJI dan, 45.000 žarnic'
      }
    },
    {
      path: '/lamps/:manufacturer/:model',
      name: 'Lamps',
      component: Lamps
    },
    {
      path: '/o-nas',
      name: 'O nas',
      component: About,
      meta: {
        title: 'O nas | Žarnice za projektor'
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})